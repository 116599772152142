import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { Link, useLocation, useParams } from "react-router-dom";


import { Config } from "../../Config";
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

// export const toCommaSeprated = (value) => {
//   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export const toCommaSeprated = (value) => {

  if (isNaN(value)) {
    return value;
  }

  const numStr = value.toString();

  if (numStr.includes('.')) {
    return numStr;
  }

  const len = numStr.length;

  if (len <= 3) {
    return numStr;
  } else if (len <= 5) {
    return numStr.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  } else {
    const firstPart = numStr.slice(0, len - 3);
    const lastPart = numStr.slice(len - 3);
    return firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastPart;
  }
};

const Swarnim1 = () => {

  const location = useLocation();
  const [projectName, setProjectName] = useState('');

  const [loading, setLoading] = useState(true);
  const [drop_sizes, setdrop_sizes] = useState([]);
  const [terms, setTerms] = useState([]);
  // const [priceList, setpriceList] = useState([]);
  const [labels, setLabels] = useState([]);
  const [price, setPrice] = useState([]);
  const [into, setIntro] = useState([]);
  const [project_download, setProjectDownload] = useState([]);
  const [project_info, setProjectInfo] = useState([]);
  const [price_lable, setPriceLable] = useState([]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    if (location.state && location.state.project) {
      setProjectName(location.state.project);
      localStorage.setItem('projectName', location.state.project);
    } else {
      const savedProjectName = localStorage.getItem('projectName');
      if (savedProjectName) {
        setProjectName(savedProjectName);
      }
    }
  }, [location.state]);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/project_info`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const data = response.data.project_page;

        setTerms(data.terms);
        setPrice(data.price_list1);
        setIntro(data.intro);
        setProjectDownload(data.project_download);
        setLabels(data.price_label);
        setProjectInfo(data.project_info);
        setPriceLable(data.price_list[0].price_title);

        setLoading(false);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, [location.state]);


  const [columnVisibility, setColumnVisibility] = useState({
    size: true,
    rate: true,
    registration_amount: true,
    allotment: true,
    plc: true,
    oc: true,
    other_charges: true,
    total_cost: true,
    apply: true,
    bsp: true
  });

  useEffect(() => {
    if (price.length > 0) {
      const firstRow = price.find(item => item.project_name === projectName);
      if (firstRow) {
        const visibility = {};
        Object.keys(firstRow).forEach(column => {
          visibility[column] = firstRow[column] !== null;
        });
        setColumnVisibility(visibility);
      }
    }
  }, [price, projectName]);

  const getLabelsByProjectId = (projectId) => {
    const filteredLabels = labels
      .filter(item => item.project_id === projectId)
      .map(item => item.label);

    return filteredLabels;
  };

  const projectId = location?.state?.project;
  const label = getLabelsByProjectId(projectId);

  // console.log("price", price.filter(term => term.project_name === projectName), labels.reverse());

  useEffect(() => {
    // Check if price has a value
    if (price && projectName) {
      const priceList = price || [];

      const filteredData = priceList.filter(p => {
        //console.log("Project Name:", p.project_name);
        return p.project_name === projectName;
      });

      const mappedData = filteredData.flatMap(p => {
        let prices = p.price;

        if (typeof prices === 'string') {
          try {
            prices = JSON.parse(prices);
          } catch (e) {
            prices = prices.split(';').map(priceString => priceString.split(','));
          }
        }

        if (!Array.isArray(prices)) {
          prices = [];
        } else {
          prices = prices.map(priceString => {
            if (typeof priceString === 'string') {
              return priceString.split(',');
            }
            return priceString;
          });
        }

        return prices.map(priceRow => {
          const size = priceRow[0] || '';
          const registrationAmount = priceRow[2] || '';

          return {
            size: size,
            title: `${size} Sq Yard Amount ${registrationAmount}`,
          };
        });
      });

      setdrop_sizes(mappedData);
    }
  }, [price, location.state]);

  var project_slug = projectName.replaceAll(" ", "-").toLowerCase();
  //console.log("projectName1", project_slug);

  return (
    <>
      {project_slug && project_slug === "mansha-orchid" ? (
        <Helmet>
          <title>Affordable Residential Plot in Mansha Orchid, Faridabad</title>
          <meta name="description" content="Discover your dream home with Mansha Orchid plots in Faridabad. Explore affordable residential plots in a prime location and secure your future with Deen Dayal Plots." />
          <meta name="keywords" content="Affordable Residential Plot,Mansha Orchid,Mansha Orchid plot,Plots in faridabad" />
        </Helmet>
      ) : project_slug && project_slug === "south-city-1" ? (
        <Helmet>
          <title>Affordable Residential Plots in Jhajjar | South City 1 Plots </title>
          <meta name="description" content="Discover affordable residential plots in Jhajjar at South City 1 by Deen Dayal Plots. Invest in your dream home with our prime plots in Jhajjar. Explore now!" />
          <meta name="keywords" content="Affordable Residential Plot,Affordable Residential Plot in Jhajjar,South city 1 plots,Plots in Jhajjar" />
        </Helmet>
      ) : project_slug && project_slug === "signature-global-signum-plaza4" ? (
        <Helmet>
          <title>Signature Global Signum Plaza 4 in Sohna</title>
          <meta name="description" content="Discover affordable residential plots in Sohna at Signature Global Signum Plaza 4. Explore prime real estate options for building your dream home." />
          <meta name="keywords" content="Affordable Residential Plot,Affordable Residential Plot in Sohna,Signature Global Signum Plaza 4" />
        </Helmet>
      ) : project_slug && project_slug === "signature-global-signum-plaza2" ? (
        <Helmet>
          <title>Signature Global Signum Plaza 2 in Sohna</title>
          <meta name="description" content="Discover affordable residential plots in Sohna at Signature Global Signum Plaza 2. Explore prime investment opportunities with spacious plots." />
          <meta name="keywords" content="Affordable Residential Plot,Affordable Residential Plot in Sohna,Signature Global Signum Plaza 2" />
        </Helmet>
      ) : project_slug && project_slug === "signature-global-signum-plaza5" ? (
        <Helmet>
          <title>Signature Global Signum Plaza 5 in Sohna</title>
          <meta name="description" content="Discover affordable residential plots in Sohna at Signature Global Signum Plaza 5. Explore prime real estate options with excellent amenities." />
          <meta name="keywords" content="Affordable Residential Plot,Affordable Residential Plot in Sohna,Signature Global Signum Plaza 5" />
        </Helmet>
      ) : (
        <></>
      )}

      {loading && <Loader />}
      <Navbar />
      {/* <Information schemaInfo={schema89} heading="Sector 89" sectorIndex={0} status={true} /> */}
      <div className="projdetails" style={{ minHeight: "100vh" }}>
        <div className="text-center mt-5">
          <h2 className="fw-bold text-capitalize ">{projectName}</h2>
          <div className="line mt-4 mb-5 mx-auto"></div>
        </div>
        {project_info.filter(term => term.project_name === projectName).length > 0 && (
          <section className="pb-5">
            <div className="container-box">
              {project_info
                .filter(term => term.project_name === projectName)
                .map((term, index) => {
                  return (
                    <div key={index} className="table-responsive">
                      <div className="project_details" dangerouslySetInnerHTML={{ __html: term?.description?.replaceAll(/ style="[^"]*"/g, '') }} />
                    </div>
                  )
                })}
            </div>
          </section>
        )}

        {price.filter(term => term.project_name === projectName).length > 0 && (
          <section className="py-5 bg-light-green" id="pricelist">
            <div className="container-box">
              <h2 className="fw-bold mb-4">{price_lable}</h2>

              <div className="table-responsive">
                <table
                  className="table table-bordered border-secondary text-center"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      {labels.filter(term => term.project_name === projectName).slice().reverse().map((item, index) => (
                        <th key={index}>{item.label}</th>
                      ))}
                      <th>Apply</th>
                    </tr>
                  </thead>
                  <tbody>
                    {price
                      .filter(item => item.project_name === projectName)
                      .slice().reverse()
                      .map((item, outerIndex) => {
                        let prices = item.price;

                        if (typeof prices === 'string') {
                          try {
                            prices = JSON.parse(prices);
                          } catch (e) {
                            prices = prices.split(';').map(priceString => priceString.split(','));
                          }
                        }

                        if (!Array.isArray(prices)) {
                          prices = [];
                        } else {
                          prices = prices.map(priceString => {
                            if (typeof priceString === 'string') {
                              return priceString.split(',');
                            }
                            return priceString;
                          });
                        }

                        let counter = 0; // Initialize counter for the sequence number within each item

                        return prices.map((priceRow, innerIndex) => {
                          const sequenceNumber = outerIndex * prices.length + innerIndex + 1; // Calculate sequence number

                          const size = priceRow[0] || '';
                          const registrationAmount = priceRow[2] || '';

                          return (
                            <tr key={`${outerIndex}-${innerIndex}`}>
                              <td>{sequenceNumber}</td>
                              {priceRow.map((price, priceIndex) => (
                                <td key={priceIndex}>{toCommaSeprated(price)}</td>
                              ))}
                              <td>
                                {item.status === 0 ? (
                                  <div
                                    className="text-decoration-none text-red"
                                    onClick={() => swal("Registration Closed", "", "warning")}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Registration Closed
                                  </div>
                                ) : item.status === 2 ? (
                                  <div className="text-decoration-none text-gray">
                                    NA
                                  </div>
                                ) : (
                                  <Link
                                    to={`${Config.appUrl}register`}
                                    state={{
                                      index: counter++,
                                      size: `${size} Sq Yard Amount ${registrationAmount}`,
                                      project: projectName,
                                      sizes: drop_sizes
                                    }}
                                    className="text-decoration-none text-red"
                                  >
                                    Registration Open
                                  </Link>
                                )}
                              </td>
                            </tr>
                          );
                        });
                      })}
                  </tbody>

                </table>
              </div>
            </div>
          </section>
        )}

        {project_info.filter(term => term.project_name === projectName && term.terms_title).length > 0 &&
          (
            <section className="py-5 ">
              <div className="container-box">
                <div className="row align-items-center justify-content-between">
                  {project_info
                    .filter(term => term.project_name === projectName)
                    .map((term, index) => (
                      <div className="col-md-8">
                        <h2 className="fw-bold mb-4">{term.terms_title}</h2>
                        <div>
                          <div key={index} dangerouslySetInnerHTML={{ __html: term.terms_description }} />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          )}


        {project_download.filter(item => item.project_name === projectName).length > 0 && (
          <section className="py-5 bg-light-green">
            <div className="container-box py-md-4">
              <h2 className="fw-bold mb-4">Downloads Section</h2>
              <div className="table-responsive">
                <table className="table w-100  table-bordered">
                  <thead>
                    <tr>
                      <th className="col-1 text-center">Sr No.</th>
                      <th>Document Type</th>
                      <th className="text-center">Status</th>
                      <th className="col-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project_download
                      .filter(item => item.project_name === projectName)
                      .map((item, index) => (
                        <tr key={index}>
                          <td className="h-auto text-center">{index + 1}</td>
                          <td className="h-auto text-capitalize">{item.document_name}</td>
                          <td className="h-auto text-capitalize text-center">Approved</td>
                          <td className="h-auto text-center">
                            <a
                              href={`${Config.docUrl}/${item.download_pdf}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-sm btn-success btn-width"
                              download={true}
                            >
                              <i className="bi bi-download me-2"></i>
                              Download
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )}

      </div>

      <Footer />

    </>
  );
};




export default Swarnim1;
